<template>
  <v-theme-provider dark>
    <base-section id="Agenda-feature" :min-height="minHeight">
      <div class="text-center">
        <div class="text-h4 my-10">
          {{ $t("reminderfeature.line1") }}
        </div>
        <v-row>
          <v-col>
            <div class="text-rigth">
              <v-container>
                <base-img
                  :src="
                    require('@/assets/FeatureNotificationAllExampleCompressed.png')
                  "
                  alt="Agenda"
                  class="mx-auto"
                  max-width="400"
                />
              </v-container>
            </div>
          </v-col>
          <v-col>
            <div class="text-h4 text-left mt-5 ml-5">
              {{ $t("reminderfeature.line2") }}
            </div>
            <div class="text-h6 text-left mt-8 ml-5">
              {{ $t("reminderfeature.tagline1") }}
            </div>
            <kdw-btn
              id="accountCreation"
              class="mt-10"
              @click="openConnexion()"
            >
              {{ $t("button.createAccountInfo") }}
            </kdw-btn>
          </v-col>
        </v-row>
      </div>
    </base-section>
  </v-theme-provider>
</template>

<script>
import { navigate } from "@/helpers/Navigation";
export default {
  name: "SectionFeatureProReminders",

  data: () => ({}),
  computed: {
    minHeight() {
      const height = this.$vuetify.breakpoint.mdAndUp ? "100vh" : "50vh";

      return `Math.trunc(calc(${height} - ${this.$vuetify.application.top}px) * 0.8)`;
    },
  },
  methods: {
    openConnexion() {
      navigate(this, "PLogin");
    },
  },
};
</script>
